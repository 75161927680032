import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';
import { UtilService } from 'src/app/service/util.service';
import { LoginApiShareService } from 'src/app/service/login-api-share.service';
@Component({
  selector: 'app-update-income-details',
  templateUrl: './update-income-details.component.html',
  styleUrls: ['./update-income-details.component.css']
})
export class UpdateIncomeDetailsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UpdateIncomeDetailsComponent>,
    private constantService :ConstantService,
    private utilService: UtilService,
    private loginApiShareService: LoginApiShareService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      // this.image = data.dataImage;
      dialogRef.disableClose = true;
  }
  options: FormGroup;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('always');
  form: FormGroup;
  defaultchecked =true;
  btndisabled = false;
  showincome :boolean;
  showemail : boolean;
  declarationList;
  emailbelowtorelation;
  selectedMobile: any;
  selectedEmail: any;
  clientCode:string;
  selectedincome:string;
  email : string;
  mobile : string;
  ngOnInit(): void {
    this.selectedincome ='Below 1Lac';
    let income =JSON.parse(this.constantService.getLocalStorageData('incomeproof'));
    let email =JSON.parse(this.constantService.getLocalStorageData('emailproof'));
    this.clientCode= this.constantService.getDecryptedLocalStorageData('clientCode');
    this.email = this.constantService.getDecryptedLocalStorageData('Email');
    this.mobile = this.constantService.getDecryptedLocalStorageData('mobileNumber');
    let mobileproof =JSON.parse(this.constantService.getLocalStorageData('mobileproof'));
    if (email) {
     this.selectedMobile = 'Self';
     this.selectedEmail = 'Self';
    }
   this.showincome = income || !(email || mobileproof);
   
  }

  acknowledgement(event): void{
    try{
      if(event.checked == true){
        this.btndisabled = false;
      }
      else{
        this.btndisabled = true;
      }
    }
    catch(e){
      console.log(e,'acknowledgement');
    }
    
  }
  updateincodedata() : void{
    try{
      this.showincome =false;
      this.constantService.setLocalStorageData('incomeproof',false);
    }
    catch(e){
      console.log(e,'updateincodedata');
    }
  }
  async getDeclarationList() : Promise<void>{
    await this.loginApiShareService.consentDeclarationList(this.clientCode)
        .then(async (response) => {
          if (response?.error) {
            this.utilService.logApiData(this.clientCode, 'Failure - consentDeclarationList', '', JSON.stringify(response));
            return
          }
          else {
            this.utilService.logApiData(this.clientCode, 'consentDeclarationList', '', JSON.stringify(response));
          }
          
            this.declarationList = response;
            this.emailbelowtorelation = response;
            this.selectedMobile = this.declarationList[0].Text;
            this.selectedEmail = this.emailbelowtorelation[0].Text;
        });
        
  }
  
  closeincomepopup(): void{
    try{
      this.dialogRef.close({selectedincome:this.selectedincome,event:'success'});
      this.constantService.setLocalStorageData('emailproof',false);
      this.constantService.setLocalStorageData('mobileproof',false);    
    }
    catch(e){
      console.log(e,'closeincomepopup');
    }
    
  }
}
