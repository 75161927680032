import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConstantService } from 'src/app/service/constant.service';
import { DataService } from 'src/app/service/data.service';
import { UtilService } from 'src/app/service/util.service';
import { StoreDataServiceService } from 'src/app/store-data-service.service';

import { OtpSuccessfullyVerifiedComponent } from '../otp-successfully-verified/otp-successfully-verified.component';
import { LoginApiShareService } from 'src/app/service/login-api-share.service';
import { APP_SETTINGS, AppSettings } from 'src/app/settings';
import { EncryptionService } from 'src/app/service/encryption.service';
@Component({
  selector: 'app-otp-verifications',
  templateUrl: './otp-verifications.component.html',
  styleUrls: ['./otp-verifications.component.css']
})
export class OtpVerificationsComponent implements OnInit {


  @ViewChild('otpOne') otpOne: ElementRef;
  @ViewChild('otpTwo') otpTwo: ElementRef;
  @ViewChild('otpThree') otpThree: ElementRef;
  @ViewChild('otpFour') otpFour: ElementRef;
  @ViewChild('otpFive') otpFive: ElementRef;
  @ViewChild('otpSix') otpSix: ElementRef;

  otpForm = this.fb.group({
    otpDigitOne: [null, Validators.required],
    otpDigitTwo: [null, Validators.required],
    otpDigitThree: [null, Validators.required],
    otpDigitFour: [null, Validators.required],
    otpDigitFive: [null, Validators.required],
    otpDigitSix: [null, Validators.required],
    
  });

  constructor(
    @Inject(APP_SETTINGS) 
    private settings: AppSettings,
    private encryptionService: EncryptionService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OtpVerificationsComponent>,
    private constantService :ConstantService,
    public datepipe: DatePipe,
    public utilService : UtilService,
    public dialog: MatDialog,
    public dataService :DataService,
    private router: Router,
    public storeDataServiceService :StoreDataServiceService,
    public loginApiShareService :LoginApiShareService,
    @Inject(MAT_DIALOG_DATA) public data: any){
    dialogRef.disableClose = true;
  }
  errormsg = false;
  otpArr: string[] = ['', '', '', '', '', ''];
  clientCode:string;
  disableBtn :boolean =true;
  showLoader :boolean = false;
  userComingFrom :null;

  hrs: string = '00';
  mins: string = '00';
  secs: string = '00';
  interval: any;
  play: boolean = true;


  async ngOnInit(): Promise<void> {
    this.userComingFrom = this.data.type;
    this.clientCode = this.constantService.getDecryptedLocalStorageData('clientCode');
    if(this.userComingFrom =='freezeAccount'){
      await this.generateOtpToUnfreezeAccount();
    }
  }
  mpinInput(event: KeyboardEvent, index: number) {
    try{
        this.errormsg = false;
        var targetElement = event.target as HTMLInputElement;
        if (!/^[0-9]+$/.test(event.key) && event.key != 'Backspace' && event.key != 'Tab') {
          event.preventDefault();
      } else if (String(this.otpArr[index]).length >= 0 && event.key != 'Backspace' && event.key != 'Tab') {
          event.preventDefault();
         
          var nextElement = targetElement.nextSibling as HTMLInputElement;
          this.otpArr[index] = event.key;
          if (index < 5) {
              nextElement.focus();
          }
          if(this.otpArr[0]!=""  && this.otpArr[1]!="" && this.otpArr[2]!="" && this.otpArr[3]!="" && this.otpArr[4]!="" && this.otpArr[5]!=""){
            this.disableBtn =false;
          }
          else{
            this.disableBtn =true;
          }
      } else if (event.key == 'Backspace') {
          this.otpArr[index] = '';
          var prevElement = targetElement.previousSibling as HTMLInputElement;
          event.preventDefault();
          if(index > 0) {
              prevElement.focus();
              this.disableBtn =true;
          }
      }
      else if (event.key == 'Tab') {
        this.otpArr[index] = '';
        var prevElement = targetElement.previousSibling as HTMLInputElement;
        event.preventDefault();
        prevElement.focus();
        }
    }
    catch(e){
      console.log(e);
    }
  }
  async verifyoptForNominee(): Promise<any>{
    try{
      if(this.userComingFrom =='freezeAccount'){
        this.unfreezeAccount();
      }
      else{
        let token = await this.utilService.generateToken(this.clientCode);
        this.generateAndverifyOtp(token,'VerifyOTP');
      }
      
    }
    catch(e){
      console.log(e,"verifyoptForNominee");
    }
  }  

  NomineegetAuthKey(): string {
    let authkey=null;
    try{
       authkey ='Basic ' + this.utilService.convertTextToBase64(this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tokenUserName) + ':' + this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tokenPassword));
       return authkey;
    }
    catch(e){
      console.log(e,"NomineegetAuthKey");
      return authkey =null;
    }
    
  }
  
  async generateAndverifyOtp(token,otptype){
    this.showLoader =true;
    try{
      let otpValue =
      this.otpArr[0].toString() +
      this.otpArr[1].toString() +
      this.otpArr[2].toString() +
      this.otpArr[3].toString() +
      this.otpArr[4].toString() +
      this.otpArr[5].toString();
      const date = new Date();
      const clientCode = this.clientCode;
      const todayDt = this.datepipe.transform(date, this.constantService.checksumDateFormate);
      const parameters = 'Web' +clientCode + otptype + 'Client' + todayDt;
      let checksumkey = this.utilService.generateCheckSumActivation(parameters);
          let objrequest ={
            head: {
                CheckSumValue: checksumkey,
                IPAddress: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress(),
                AppSource: "Web"
            },
            
            body: {
                ClientCode: this.clientCode,
                OTPType: otptype,
                OTP:otpValue,
                UserType:"Client"
            }
           }
           const Auth ='123456789:' + token;
           const Authorizationkey = this.NomineegetAuthKey();
          await this.loginApiShareService.nomineeOtp(Auth,Authorizationkey,objrequest)
            .then(async (otpResponse: any) => {
              if (otpResponse?.error) {
                this.utilService.logApiData(this.clientCode, 'Failure - generateAndverifyOtp', JSON.stringify(objrequest), JSON.stringify(otpResponse));
                this.showLoader =false;
                return
              }
              else {
                this.utilService.logApiData(this.clientCode, 'generateAndverifyOtp', JSON.stringify(objrequest), JSON.stringify(otpResponse));
              }
              if(otpResponse != null){
                if(otpResponse.head.Status =='0' && otpResponse.body.Status =='0'){
                  this.showLoader =false;
                  this.dialogRef.close();
                  const dialogRef = this.dialog.open(OtpSuccessfullyVerifiedComponent, {});
                  dialogRef.afterClosed().subscribe(result => {
                    console.log(result);
                    if(result =='success'){
                      this.dataService.sendData({
                        result
                      });
                      this.router.navigateByUrl('login-exist');
                    }
                  });
                  
                }
                else if(otpResponse.head.Status =='0' && otpResponse.body.Status =='1'){
                  this.errormsg = otpResponse.body.Message;
                  this.showLoader =false;

                }
                else if(otpResponse.head.Status =='0' && otpResponse.body.Status =='1'){
                    this.errormsg = otpResponse.body.Message;
                    this.showLoader =false;
                }
              }
              
            })
            .catch(async (error) => {
              this.showLoader =false;
                if (error.response.status == 403 || error.response.status == 401) {
                  await this.verifyoptForNominee();
                }
          });
    }
    catch(e){
      console.log(e)
    }
  }
  async generateOtpToUnfreezeAccount() {
    try {
      var jwtToken = this.constantService.getDecryptedLocalStorageData('JwtToken');
      var clientCode = this.constantService.getDecryptedLocalStorageData('clientCode')
      let objrequest = {
        head: {
          appName: "Login",
          osName: "WebLogin",
          appVersion: "1.0",
          clientIP: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP : this.storeDataServiceService.getIpAddress(),
        },
        body: {
          ClientCode: clientCode
        }
      }

      const Authorizationkey = 'Bearer ' + jwtToken;
      await this.loginApiShareService.getOtpForFreezeUser(Authorizationkey, objrequest)
        .then(async (response) => {
          if (response?.error) {
            this.utilService.logApiData(clientCode, 'Failure - GetOTPFreezeUnFreeze', JSON.stringify(objrequest), JSON.stringify(response));
            return;
          }
          else {
            this.utilService.logApiData(clientCode, 'GetOTPFreezeUnFreeze', JSON.stringify(objrequest), JSON.stringify(response));
          }
          if(response.data.Status != 2){
            this.startTimer();
          }
          else{
            this.errormsg = response.data.Message;
            this.disableBtn =true; // disable button
            this.showLoader =false;
          }
          
        });
    }
    catch (e) {
      console.log(e, "unfreezeAccount");
    }
  }
  async unfreezeAccount() {
    try {
      let otpValue =
      this.otpArr[0].toString() +
      this.otpArr[1].toString() +
      this.otpArr[2].toString() +
      this.otpArr[3].toString() +
      this.otpArr[4].toString() +
      this.otpArr[5].toString();

      var jwtToken = this.constantService.getDecryptedLocalStorageData('JwtToken');
      let objrequest = {
        head: {
          appName: "Login",
          osName: "WebLogin",
          appVersion: "1.0",
          clientIP: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP : this.storeDataServiceService.getIpAddress(),
        },
        body: {
          ClientCode: this.clientCode,
          OTP: otpValue,
          FreezeStatus: "UnFreeze"
        }
      }

      const Authorizationkey = 'Bearer ' + jwtToken;
      await this.loginApiShareService.verifyOtpForFreezeUser(Authorizationkey, objrequest)
        .then(async (response) => {
          if (response?.error) {
            this.utilService.logApiData(this.clientCode, 'Failure - ValidateOTPFreezeUnFreeze', JSON.stringify(objrequest), JSON.stringify(response));
            return;
          }
          else {
            this.utilService.logApiData(this.clientCode, 'ValidateOTPFreezeUnFreeze', JSON.stringify(objrequest), JSON.stringify(response));
            if(response.Status == 0){
              this.dialogRef.close('success');
            }
            else{
              this.errormsg = response.Message;
              this.showLoader =false;
              this.otpArr =['', '', '', '', '', ''];
              this.disableBtn =true; // disable button
          }
        }
          
        });
        
    }
    catch (e) {
      console.log(e, "unfreezeAccount");
    }
  }
  startTimer(time: number = 30): void {
    time = time - 1;
    this.play = true;
    var hrs: any = '';
    var mins = Math.floor(time / 60);
    time = time - mins * 60;
    time = this.makeDigitLength(time);
    this.mins = this.makeDigitLength(mins);
    if (this.play) {
        this.secs = String(time);
        this.startCore();
    }
  }
  makeDigitLength(number: any, length: number = 2) {
    let len = String(number).length;
    if (len < length) {
        let zeroStr = Array(length - len)
            .fill('0')
            .reduce((a, b) => {
                return a + b;
            });
        number = zeroStr + number;
        return number;
    }
    return number;
  }
  startCore() {
    var secs: number;
    this.interval = setInterval(() => {
        this.secs = this.makeDigitLength(Number(this.secs) - 1);
        if (this.secs == '00') {
            if (Number(this.mins) > 0) {
                this.secs = '59';
                this.mins = this.makeDigitLength(Number(this.mins) - 1);
            } else {
                clearInterval(this.interval);
                this.play = false;
                return;
            }
        }
    }, 1000);
  }
  resendOTP(){
    if(this.userComingFrom =='freezeAccount'){
      this.generateOtpToUnfreezeAccount();
    }
  }

}
