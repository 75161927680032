import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConstantService } from 'src/app/service/constant.service';
import { StoreDataServiceService } from 'src/app/store-data-service.service';
import { LoginApiShareService } from 'src/app/service/login-api-share.service';
import { UtilService } from 'src/app/service/util.service';
import { OtpVerificationsComponent } from '../otp-verifications/otp-verifications.component';

@Component({
  selector: 'app-freeze-unfreeze',
  templateUrl: './freeze-unfreeze.component.html',
  styleUrls: ['./freeze-unfreeze.component.css']
})
export class FreezeUnfreezeComponent {

  constructor
  (
    public storeDataServiceService : StoreDataServiceService,
    public dialogRef: MatDialogRef<FreezeUnfreezeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public constantService : ConstantService,
    public loginApiShareService : LoginApiShareService,
    public utilService : UtilService,
    public dialogRefOtp: MatDialogRef<OtpVerificationsComponent>,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }
  skipForNow(){
      this.storeDataServiceService.setIsFreezeAccount(0); // 0 = Skip for now
      this.dialogRef.close('skip');
  }
  openOtpDialog(){
    this.dialogRef.close('success');
  }

}
