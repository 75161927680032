import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from './encryption.service';
@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor(
    private cookies: CookieService,
    private encryptionService: EncryptionService,
  ) {}
  
  LocalIP = '10.254.0.195';
  checksumDateFormate = 'ddMMyy';
  showIncomePopup= false;
  showMobileEmailPopup = false;
  showNomineePopup = false;
  showFnoPopup = false;
  businessID='1';
  companyID='2';
  AppSource ="WebNew_Reg";
  swarajauthSecretKey ='AXDPM9984H';
// SET GET Encrypt Decrypt Cookie Data

  setEncryptedCookieData(cookieName, cookieValue) {
    try {
      if(cookieName != null && cookieValue != null){
        this.cookies.set(cookieName, this.encryptionService.encryptUsingAES_256(cookieValue));
      }
    } catch(e){
      console.log('Constant service .setEncryptedCookieData - ',e);
      return null;
    }
  }

  getDecryptedCookieData(cookieName) {
    try {
      if(cookieName != null){
        return this.encryptionService.decryptUsingAES_256(this.cookies.get(cookieName));
      }
      else{
        return null;
      }
      
    } catch(e){
      console.log('Constant service .getDecryptedCookieData - ',e);
      return null;
    }
  }
  // SET GET Encrypt Decrypt Session Data

  setEncryptedSessionData(sessionName, sessionValue) {
    try {
      if(sessionName != null && sessionValue != null){
        sessionStorage.setItem(sessionName, this.encryptionService.encryptUsingAES_256(sessionValue));
      }
      
    } catch(e){
      console.log('Constant service .setEncryptedSessionData - ',e);
      return null;
    }
  }

  getDecryptedSessionData(sessionName) {
    try {
      if(sessionName != null){
        return this.encryptionService.decryptUsingAES_256(sessionStorage.getItem(sessionName));
      }
      else{
        return null;
      }
    } catch(e){
      console.log('Constant service .getDecryptedSessionData - ',e);
      return null;
    }
  }

  setSessionData(sessionName, sessionValue) {
    try {
      sessionStorage.setItem(sessionName, sessionValue);
    } catch(e){
      console.log('Constant service .setSessionData - ',e);
      return null;
    }
  }

  setEncryptedLocalStorageData(storageName, storageValue:any) {
    try {
      localStorage.setItem(storageName, this.encryptionService.encryptUsingAES_256(storageValue.toString().trim()));
    } catch(e){
      console.log('Constant service .setEncryptedLocalStorageData - ',e);
      return null;
    }
  }
  
  getDecryptedLocalStorageData(storageName):any {
    try {
      if(localStorage.getItem(storageName) != null){
        return this.encryptionService.decryptUsingAES_256(localStorage.getItem(storageName)).toString().trim();
      }
      
    } catch(e){
      console.log('Constant service .setDecryptedLocalStorageData - ',e);
      return null;
    }
  }

  setLocalStorageData(storageName, storageValue) {
    try {
      localStorage.setItem(storageName, storageValue);
    } catch(e){
      console.log('Constant service .setLocalStorageData - ',e);
      return null;
    }
  }

  getLocalStorageData(storageName):any {
    try {
      return localStorage.getItem(storageName);
    } catch(e){
      console.log('Constant service .getLocalStorageData - ',e);
      return null;
    }
  }


}
