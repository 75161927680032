/* eslint-disable no-var */
import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ConstantService } from './constant.service';
import { MatDialog } from '@angular/material/dialog';
import { RiskClosureDialogComponent } from '../pre-login/dialog-box/risk-closure-dialog/risk-closure-dialog.component';
import { UrgentNominationRegistrationComponent } from '../pre-login/dialog-box/urgent-nomination-registration/urgent-nomination-registration.component';
import { UpdateIncomeDetailsComponent } from '../pre-login/dialog-box/update-income-details/update-income-details.component';
import { ThankYouComponent } from '../pre-login/dialog-box/thank-you/thank-you.component';
import { StoreDataServiceService } from '../store-data-service.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginApiShareService } from './login-api-share.service';
import { APP_SETTINGS, AppSettings } from '../settings';
import { EncryptionService } from './encryption.service';
import { DatePipe } from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ToastrService } from 'ngx-toastr';
import { FreezeUnfreezeComponent } from '../pre-login/dialog-box/freeze-unfreeze/freeze-unfreeze.component';
import { OtpVerificationsComponent } from '../pre-login/dialog-box/otp-verifications/otp-verifications.component';


declare function setCookie(cookieName,cookieValue)
@Injectable({
    providedIn: 'root'
})
export class UtilService {

  apiratelimit : number = 0;

    constructor(
        @Inject(APP_SETTINGS) 
        private settings: AppSettings,
        public constantService: ConstantService,
        private storeDataServiceService: StoreDataServiceService,
        private cookieService: CookieService,
        public dialog: MatDialog,
        public loginApiShareService: LoginApiShareService,
        private encryptionService: EncryptionService,
        public datepipe: DatePipe,
        private toastr: ToastrService
        ) {}

    isValidDate(dateString) {
        // First check for the pattern
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

        // Parse the date parts to integers
        var parts = dateString.split('/');
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1920 || year > 2200 || month == 0 || month > 12) return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }

    addForwardSlash(text: string) {
        // console.log(text);
        text = text.replace(/\D+/g, '');
        var newtext = text;
        if (text.length >= 2) {
            newtext = text.substring(0, 2) + '/';
            if (text.length < 4) {
                newtext += text.substring(2);
            }
        }
        if (text.length >= 4) {
            newtext += text.substring(2, 4) + '/';
        }
        if (text.length > 4) {
            newtext += text.substring(4);
        }

        return newtext;
    }
    convertTextToBase64(inputText) {
        const val = CryptoJS.enc.Utf8.parse(inputText);
        const base64 = CryptoJS.enc.Base64.stringify(val);
        return base64;
    }

    goToPrevPage() {
        window.history.back();
    }
    async logApiData(clientcode: string, methodName, request: string, response: string): Promise<void> {
        let checksumkey = this.generateCheckSum(clientcode + methodName + this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).privateChecksumkey));
        try {
            let objrequest = {
                head: {
                    appName: "CommonAPI",
                    osName: "Web",
                    appVersion: "1.0",
                    clientIP: "::1"
                },
                body: {
                    ClientCode: clientcode,
                    MethodName: methodName,
                    Request: request,
                    Response: response,
                    Checksum: checksumkey
                }
            }
            let basicAuth = "Basic " + "dGVzdDoxMjM0NQ==";
            await this.loginApiShareService.insertApiLog(basicAuth,objrequest)
                .then(async (response) => {

                });
        }
        catch (error) {
            console.log(error, "logApiData")
        }

    }
    generateCheckSum(parameters): string {
        const id = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).privateChecksumkey) + parameters;
        const md5Hash = CryptoJS.MD5(id);
        return md5Hash.toString().substr(0, 16).toUpperCase();
         
    }
    async getClientPersonalDetails(Jwttoken: string, clientCode) {
        let personalinfodata;
        try {
            let objrequest = {
                "head": {
                    "appVer": "1.0",
                    "appName": "5P",
                    "osName": "WEB",
                    "key": this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).boApiHeadParamKey)
                },
                "body": {
                    "clientcode": clientCode
                }
            }
            let Authorization = 'Bearer ' + Jwttoken;
            
            await this.loginApiShareService.clientPersonalDetails(Authorization,objrequest)
                .then(async (response) => {
                    if (response?.error || response?.name) {
                        this.logApiData(clientCode, 'Failure - getClientPersonalDetails', JSON.stringify(objrequest), JSON.stringify(response));
                        personalinfodata =null;
                        return
                      }
                      else {
                        personalinfodata = response;
                        this.logApiData(clientCode, 'getClientPersonalDetails', JSON.stringify(objrequest), JSON.stringify(response));
                      }
                });
            return personalinfodata;
        }
        catch (error) {
            return personalinfodata = null;
        }

    }
    async getIncomeRangeData(clientCode, Jwttoken) : Promise<any>{
        let incomedata = null;
        try {
            let objincome = {
                head: {
                    appVer: "1.0",
                    appName: "5P",
                    osName: "WEB",
                    key: this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).boApiHeadParamKey)
                },
                body: {
                    clientcode: clientCode
                }
            }
            let authorization = 'Bearer ' + Jwttoken;
            await this.loginApiShareService.incomerange(authorization, objincome)
                .then(async (response) => {
                   if('status' in response || 'name' in response){
                    this.logApiData(clientCode,'Failure-getIncomeRangeData-1',JSON.stringify(objincome),JSON.stringify(response));
                    return incomedata = null;
                   }
                    else{
                      incomedata = response;
                      this.logApiData(clientCode,'getIncomeRangeData',JSON.stringify(objincome),JSON.stringify(incomedata));
                    }
                    return incomedata;
                })
                .catch((error) => {
                   this.logApiData(clientCode,'Failure-getIncomeRangeData-2',JSON.stringify(objincome),JSON.stringify(error));
                   incomedata = null;
                   return incomedata;
                });
            return incomedata;
        }
        catch (error) {
            return incomedata = null;
        }

    }
    generateCheckSumActivation(parameters): string {
        const id = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).activationMerchentkey) + parameters;
        const md5Hash = CryptoJS.MD5(id);
        return md5Hash.toString().substr(0, 16).toUpperCase();

    }
    async generateToken(clientcode) : Promise<string>{
        let token =null;
        try{
          const request = {
            RequestSource: "Web"
          };
          const Authorizationkey = this.getAuthKey();
        //  await LoginApiService.getLoginApiService()
        //   .ActivationGenerateToken(environment.Activation_Ocpkey,clientcode,'login','Web',Authorizationkey,request)
        await this.loginApiShareService.activationTokenGenerate(clientcode,Authorizationkey,request)
          .then(async(tokenresponse: any) => {
            if(tokenresponse?.error || tokenresponse?.name)
              {
                this.logApiData(clientcode,'Failure - activationTokenGenerate',JSON.stringify(request),JSON.stringify(tokenresponse));
                return token =null;
              }
              else{
                this.logApiData(clientcode,'activationTokenGenerate',JSON.stringify(request),JSON.stringify(tokenresponse));
              }
             if(tokenresponse != null){
              if(tokenresponse.head.Status ==1 && tokenresponse.body.Status=='1'){
                token =tokenresponse.body.Data;
              }
              return token;
             }
          });
          return token;
        }
        catch(e){
          console.log(e);
          return token =null;
        }
        
    }
    getAuthKey(): string {
        let authkey=null;
        try{
           authkey ='Basic ' + this.convertTextToBase64(this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tokenUserName) + ':' + this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tokenPassword));
           return authkey;
        }
        catch(e){
          console.log(e,"getAuthKey");
          return authkey =null;
        }
    
    }
    async checkForAllPopup(){
      try{
          let selectOptionUrl = this.constantService.getDecryptedLocalStorageData('selectedOption');
          let returnUrl = this.constantService.getDecryptedLocalStorageData('returnUrl');
          let clientCode = this.constantService.getDecryptedLocalStorageData('clientCode');
          var isDormantAccount = this.storeDataServiceService.isDormantAccount;
          let isfirebasePopupEnabled =this.storeDataServiceService.getFirebasePopupConfigration();
          isfirebasePopupEnabled = isfirebasePopupEnabled == undefined ? true : isfirebasePopupEnabled;
          if(isfirebasePopupEnabled == true){
            if(this.apiratelimit == 0){
              await this.CheckForFreezeAccount().then(async(response)=>{
                if(response.StatusCode == 200){ 
                 this.storeDataServiceService.setIsFreezeAccount(response.FreezeStatus); //response.FreezeStatus
               }
               else{
                 if(response?.error || response?.name){
                   this.storeDataServiceService.setIsFreezeAccount(0); // default value un-freeze
                 }
               }
              });
            }
            
          }
          else{
            this.constantService.setEncryptedLocalStorageData('NomineePop','NO');
            this.constantService.setLocalStorageData('incomeproof',false);
            this.constantService.setLocalStorageData('emailproof',false);
            this.constantService.setLocalStorageData('mobileproof',false);
            this.constantService.setLocalStorageData('AllowNseDerivative','N');
            this.constantService.setLocalStorageData('AllowBseDerivative','N');
            this.storeDataServiceService.setIsFreezeAccount(0); // default value un-freeze
          }
        // end
        let isFreezeAccount = this.storeDataServiceService.getIsFreezeAccount();
        let isNomineepopupRequired = this.constantService.getDecryptedLocalStorageData('NomineePop');
        let income =JSON.parse(this.constantService.getLocalStorageData('incomeproof'));
        let emailproof =JSON.parse(this.constantService.getLocalStorageData('emailproof'));
        let mobileproof =JSON.parse(this.constantService.getLocalStorageData('mobileproof'));
        let IsAllowNseDerivative = this.constantService.getLocalStorageData("AllowNseDerivative") ==null ? 'N' :this.constantService.getLocalStorageData("AllowNseDerivative");
        let IsAllowBseDerivative = this.constantService.getLocalStorageData("AllowBseDerivative") ==null ? 'N' :this.constantService.getLocalStorageData("AllowBseDerivative");
        
          if (income == false && (emailproof == false || mobileproof == false) && (IsAllowNseDerivative == 'N' || IsAllowNseDerivative == 'No') && (IsAllowBseDerivative == 'N' || IsAllowBseDerivative == 'No') && isNomineepopupRequired == 'NO' && isFreezeAccount == 0) {
              //this.logApiData(clientCode,'Success',window.location.hostname,returnUrl == undefined ? '{}' : returnUrl);
              if(isfirebasePopupEnabled){
                await this.formAuthenticationApiCall(clientCode);
              }
             
              this.lastUserLogingDetails();
              await this.setAllCookies().then(async()=>{
                  this.logApiData(clientCode,'Success',window.location.hostname,returnUrl == undefined ? '{}' : returnUrl);

                if(this.constantService.getDecryptedLocalStorageData("vid"))
                {
                  await this.generateVendorTokenAPICall();
                }
                else
                {
                  if(isDormantAccount == 'A'){
                    if (selectOptionUrl != undefined  && returnUrl == undefined) {
                         localStorage.removeItem('returnUrl') // clear return url from local storage
                        if (selectOptionUrl == 'Tradestation') {
                            if(this.storeDataServiceService.userDetails[0]['redirectionType'] == '0'){
                              window.location.href = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tradestationUrl); // old tradestation
                            }
                            else{
                              // for 1,2 new tradestation
                              window.location.href = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tradestationNewUrl); // new tradestation
                            }
                        }
                        else if (selectOptionUrl == 'FnO') {
                            window.location.href = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).fnoUrl);
                        }
                        else {
                            window.location.href = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).chartUrl);
                        }
                    }
                    // if return Url is not null then redirect to return url
                    else {
                        if (returnUrl != undefined || returnUrl != null) {
                          if (returnUrl.includes('theme')) {
                            if(returnUrl.includes('&theme')){
                              let returnurlthemeName = [] = returnUrl.split('&'); // split url with & and get theme name
                              let themeName = returnurlthemeName.filter((item) => item.includes('theme'));
                              if(themeName[0].replace('theme=','') == this.storeDataServiceService.getUser_Themes()){
                                returnUrl = returnurlthemeName[0];
                              }
                              else{
                                returnUrl = returnurlthemeName[0]+ '?theme=' + this.storeDataServiceService.getUser_Themes();
                              }
                             
                            }
                            else{
                              if(returnUrl.includes('?theme')){
                                let returnurlthemeName = [] = returnUrl.split('?');// full Url spit by ?
                                let themeName = returnurlthemeName.filter((item) => item.includes('theme'));
                                if(themeName[0].replace('theme=','') == this.storeDataServiceService.getUser_Themes()){
                                  returnUrl = returnurlthemeName[0];
                                }
                                else{
                                  returnUrl = returnurlthemeName[0] + '?theme=' + this.storeDataServiceService.getUser_Themes();
                                }
                              }
                            }
                            
                          }
                          else{
                            if(this.storeDataServiceService.getdefafulThemeChange()== 'Y'){
                              returnUrl = returnUrl + '?theme=' + this.storeDataServiceService.getUser_Themes();
                            }
                            else{
                              returnUrl = returnUrl;
                            }
                          }
                          localStorage.removeItem('returnUrl') // clear return url from local storage
                          window.location.href = returnUrl; // redirect user, where from he logged in
                        }
                        else {
                            localStorage.removeItem('returnUrl') // clear return url from local storage
                            window.location.href = this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).tradestationUrl);
                        }
                    }
                  }
                  else{
                    window.location.href ='https://emodify.5paisa.com/customer-dashboard/client-profile'; // redirect user to emodify page
                  }
                }  
              });
          }
          else {
              if (income == true) {
                  this.openIncomeproofPopup();
              }
              else if (emailproof == true || mobileproof == true) {
                  this.openIncomeproofPopup();
              }
              else if (isNomineepopupRequired == 'YES') {
                  this.openNomineePopup();
              }
              else if (isFreezeAccount == 1) {
                this.openFreezeAccountPopup();
              }

              else if (IsAllowNseDerivative == 'YES' || IsAllowNseDerivative == 'Y') {
                  this.openFNODPopup();
              }
              
              else if (IsAllowBseDerivative == 'YES' || IsAllowBseDerivative == 'Y') {
                  this.openFNODPopup();
              }
          }
      }
      catch(e){
          console.log(e,"UtileService-CheckForAllPopUp");
      }
    }
    openFNODPopup(): void {
        const dialogRef = this.dialog.open(RiskClosureDialogComponent, {});
        dialogRef.afterClosed().subscribe(result => {
          if (result == 'success') {
            if(this.constantService.getLocalStorageData("AllowNseDerivative") == 'Y' || this.constantService.getLocalStorageData("AllowNseDerivative") == 'YES') {
                this.constantService.setLocalStorageData("AllowNseDerivative",'N');
            }
            if(this.constantService.getLocalStorageData("AllowBseDerivative") =='Y' || this.constantService.getLocalStorageData("AllowBseDerivative") =='YES'){
                this.constantService.setLocalStorageData("AllowBseDerivative",'N');
            };
            this.checkForAllPopup();
          }
        });
    }
    openNomineePopup() : void{
        const dialogRef = this.dialog.open(UrgentNominationRegistrationComponent, {});
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
        });
    }
    openIncomeproofPopup(): void{
        const dialogRef = this.dialog.open(UpdateIncomeDetailsComponent, {});
        dialogRef.afterClosed().subscribe(result => {
          if(result.event =='success'){
            this.constantService.setLocalStorageData('incomeproof',false); // make this false for live data;
            this.updateIncomeDeclaration(result.selectedincome);
            const confirmdialogref = this.dialog.open(ThankYouComponent)
            confirmdialogref.afterClosed().subscribe(result => {
                if(result =='success'){
                    this.checkForAllPopup();
                }
            })
          }
        });
    }
    openFreezeAccountPopup(): void{
        const dialogRef = this.dialog.open(FreezeUnfreezeComponent);
          dialogRef.afterClosed().subscribe(result => {
            if(result == 'success'){
              // Close the first dialog
              dialogRef.close(); // close the first dialog
              const dialogRefOtp = this.dialog.open(OtpVerificationsComponent, {data : {type : 'freezeAccount'}});
              dialogRefOtp.afterClosed().subscribe(result => {
                if(result == 'success'){
                  this.storeDataServiceService.setIsFreezeAccount(0); // 1 = Freeze Account
                  const confirmdialogref = this.dialog.open(ThankYouComponent);
                  confirmdialogref.afterClosed().subscribe(result => {
                    if(result == 'success'){
                      this.checkForAllPopup();
                    }
                   });
                }
              });
              // Open the second dialog after the first one is closed
              
            }
            if(result == 'skip'){
              dialogRef.close();
              this.storeDataServiceService.setIsFreezeAccount(0); // 0 = Skip for now
              this.checkForAllPopup();
            }
          });
    }
    async updateIncomeDeclaration(selectedincome) : Promise<void>{
        try{
            let clientCode= this.constantService.getDecryptedLocalStorageData('clientCode');
            let requestdata={
                head: {
                    appVer: "1.0",
                    appName: "5P",
                    osName: "WEB",
                    key: this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).boApiHeadParamKey)
                },
                body: {
                    ClientCode: clientCode,
                    IncomeRange: selectedincome,
                    EmailId: "",
                    EmailConsent: "",
                    Mobile: "",
                    MobileConsent: ""
                }
            }
            let jwttoken =this.constantService.getDecryptedLocalStorageData('JwtToken');
            let Authorization = 'Bearer ' + jwttoken;
            await this.loginApiShareService.updateIncomeRange(Authorization, requestdata)
            .then(async (updateresponse: any) => {
                if (updateresponse?.error) {
                    this.logApiData(clientCode, 'Failure - UpdateIncomeRange', JSON.stringify(requestdata), JSON.stringify(updateresponse));
                    return
                  }
                  else {
                    this.logApiData(clientCode, 'UpdateIncomeRange',JSON.stringify(requestdata) , JSON.stringify(updateresponse));
                  }
                
            });
        }
        catch(e){
            console.log(e);
        }
    }
    async formAuthenticationApiCall(clientCode) : Promise<any>{
        let aspxresponse = null; 
        let expiryDt =this.getCurrentDateTimeString();
        let Parameters = clientCode + expiryDt + this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).authApiChecksumPrivateKey);
        let checksum =this.generateChecksumAuthApi(Parameters);
            try{
                let requestdata={
                    Version: "1.0",
                    ClientCode: clientCode,
                    Expiry: expiryDt,
                    CheckSum: checksum,
                    Userdata: 
                        {
                          ClientName: this.constantService.getDecryptedLocalStorageData('ClientName') == undefined || null ? '' : this.constantService.getDecryptedLocalStorageData('ClientName'),
                          IsDealer: "N", // As per Poonam Email
                          RMClient: "" 
                        }
                }
               
                await this.loginApiShareService.formAuthentication(requestdata)
                .then(async (FormAuthenticationresponse: any) => {
                    if (FormAuthenticationresponse?.error || FormAuthenticationresponse?.name) {
                        this.logApiData(clientCode, 'Failure - FormAuthentication', JSON.stringify(requestdata), JSON.stringify(FormAuthenticationresponse));
                        
                      }
                      else {
                        this.logApiData(clientCode,'FormAuthentication',JSON.stringify(requestdata),JSON.stringify(FormAuthenticationresponse));
                      }
                    if(FormAuthenticationresponse != null){
                        if(FormAuthenticationresponse.Status ==0){
                           let aspxaut = FormAuthenticationresponse.ASPXAUTH;
                           //setCookie('.ASPXAUTH',aspxaut);
                           this.constantService.setEncryptedLocalStorageData('ASPXAUTH',FormAuthenticationresponse.ASPXAUTH);
                           this.storeDataServiceService.setAspxAuthCookie(aspxaut);
                        }
                      
                    }
                    return aspxresponse = FormAuthenticationresponse; 
                });
                return aspxresponse;
            }
            catch(e){
                console.log(e);
                this.logApiData(clientCode, 'Failure - formAuthenticationApiCall - Catch', JSON.stringify(e), JSON.stringify('Method error - 537'));
            }
        
    }
    generateChecksumAuthApi(parameters): string {
        const id =  this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).authApiChecksumPrivateKey) + parameters;
        const md5Hash = CryptoJS.MD5(id);
        return md5Hash.toString().substr(0, 16).toUpperCase();
    }
    getCurrentDateTimeString(): string {
        const now = new Date();
        now.setHours(now.getHours() + 8); // asp.net auth cookie expiry time is 8 hours
        const year: number = now.getFullYear();
        const month: number = now.getMonth() + 1; // Months are zero-based
        const day: number = now.getDate();
        const hours: number = now.getHours();
        const minutes: number = now.getMinutes();
        const seconds: number = now.getSeconds();
      
        // Pad single-digit values with a leading zero
        const formattedMonth: string = month < 10 ? `0${month}` : `${month}`;
        const formattedDay: string = day < 10 ? `0${day}` : `${day}`;
        const formattedHours: string = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds: string = seconds < 10 ? `0${seconds}` : `${seconds}`;
      
        // Assemble the date string
        const dateString: string = `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        return dateString;
    } 
    clearlocalstorage(){
        localStorage.removeItem('isDeviceBinded');
        localStorage.removeItem('isTwoFA');
        localStorage.removeItem('EmailForConsent');
        localStorage.removeItem('NomineeConsent');
        localStorage.removeItem('response');
        localStorage.removeItem('AllowBseDerivative');
        localStorage.removeItem('NomineePop');
        localStorage.removeItem('userId');
        //localStorage.removeItem('returnUrl');
        localStorage.removeItem('ClientName');
        localStorage.removeItem('showOtpWithDob');
        localStorage.removeItem('Email');
        localStorage.removeItem('AllowNseDerivative');
        localStorage.removeItem('5Pcookies');
        localStorage.removeItem('NomineeConsent');
        localStorage.removeItem('incomeproof');
        localStorage.removeItem('emailproof');
        localStorage.removeItem('mobileproof');
        localStorage.removeItem('JwtToken');
        localStorage.removeItem('EmailForConsent');
        localStorage.removeItem('5Pcookies');
        localStorage.removeItem('DeviceID');
        localStorage.removeItem('incomeproof');
        localStorage.removeItem('emailproof');
        //localStorage.removeItem('selectedOption');
        localStorage.removeItem('mobileNumber');
        localStorage.removeItem('showOtpWithDob');
        localStorage.removeItem('response');
        localStorage.removeItem('isEditClicked');
        sessionStorage.clear();// clear session storage
        
        
    }
    getCurrentDate(date) {
      let datePart = [
        date.getMonth() + 1,
        date.getDate(),
        date.getFullYear()
      ].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0")).join("/");
      let timePart = [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      ].map((n, i) => n.toString().padStart(2, "0")).join(":");
      return datePart + " " + timePart;
    }
    async setAllCookies(): Promise<void>{
        try{
            console.log('final',JSON.stringify(this.storeDataServiceService.userDetails));
           let data = this.encryptionService.encryptUsingAES_256(JSON.stringify(this.storeDataServiceService.userDetails));
           
            let expiredDate = new Date();
            expiredDate.setDate(expiredDate.getDate() + 7 );

            let expiredDateAuth = new Date();
            expiredDateAuth.setHours(expiredDateAuth.getHours()+ 8);

            let userDetailsExpiredDate = new Date();
            userDetailsExpiredDate.setDate(userDetailsExpiredDate.getDate() + 30 );
            let domainParts = window.location.host.split('.');
            domainParts.shift();
            let domain = '.'+domainParts.join('.');
            this.cookieService.set('userDetails', data, userDetailsExpiredDate, '/', domain, true, 'None'); // .5paisa.com domain
            if(this.storeDataServiceService._5paisacookie != undefined){
                //setCookie('5paisacookie',this.storeDataServiceService._5paisacookie);
                this.cookieService.set('5paisacookie',this.storeDataServiceService._5paisacookie,expiredDate,'/',domain,true,'None'); // .5paisa.com domain
                //this.cookieService.set('5paisacookie',this.storeDataServiceService._5paisacookie,expiredDate,'/',null, true, "None");
            }
            else{
                let _5paisacookie =this.constantService.getDecryptedLocalStorageData('5Pcookies');
                setCookie('5paisacookie',_5paisacookie);
            }
            if(this.storeDataServiceService.jwtToken != undefined){
                //setCookie('JwtToken',this.storeDataServiceService._jwtToken);
                this.cookieService.set('JwtToken',this.storeDataServiceService.jwtToken,expiredDate,'/',domain,true,'None'); // .5paisa.com domain
                //this.cookieService.set('JwtToken',this.storeDataServiceService._jwtToken,expiredDate,'/',null, true, "None"); // weblogin-preprod.5paisa domain
            }
            else{
                let _jwttoken = this.constantService.getDecryptedLocalStorageData('JwtToken');
                 setCookie('JwtToken',_jwttoken);
            }
            if( this.storeDataServiceService.aspxAuth != undefined ){
                //setCookie('.ASPXAUTH',this.storeDataServiceService._ASPXAUTH);
                this.cookieService.set('.ASPXAUTH',this.storeDataServiceService.aspxAuth,expiredDateAuth,'/',domain,true,'None'); // .5paisa.com domain
                //this.cookieService.set('.ASPXAUTH',this.storeDataServiceService._ASPXAUTH,expiredDate,'/',null,true,'None'); // weblogin-preprod.5paisa domain
            }
            else{
                if(this.constantService.getDecryptedLocalStorageData('ASPXAUTH') != null || this.constantService.getDecryptedLocalStorageData('ASPXAUTH') != undefined){
                    setCookie('.ASPXAUTH',this.constantService.getDecryptedLocalStorageData('ASPXAUTH'));
                }
                
            }
            if(this.storeDataServiceService.solaceCred != undefined){
                //setCookie('SolaceCred',this.storeDataServiceService._SolaceCred);
                this.cookieService.set('SolaceCred',this.storeDataServiceService.solaceCred,expiredDate,'/',domain,true,'None'); // .5paisa.com domain
                //this.cookieService.set('SolaceCred',this.storeDataServiceService._SolaceCred,expiredDate,'/',null,true,'None'); // weblogin-preprod.5paisa domain
            }
            else{
                let solacecred = this.constantService.getDecryptedLocalStorageData('SolaceCred');
                if(solacecred != null || solacecred != undefined){
                    setCookie('SolaceCred',solacecred);
                }
                
            }
            let uniqueUID = this.constantService.getLocalStorageData('uniqueUID');
            if (uniqueUID != undefined || uniqueUID != null) {
                //setCookie('uniqueUID',uniqueUID);
                this.cookieService.set('uniqueUID', uniqueUID, expiredDate, '/', domain, true, 'None'); // .5paisa.com domain
                
            }                             
            
        }
        catch(e){
            console.log(e,'SetAllCookies');
        }
    }
    async checkforValidSession():Promise<any>{
        let _loginresponse = null;
        try {
         let clientCode = this.constantService.getDecryptedLocalStorageData('clientCode') == undefined ? '12345678' : this.constantService.getDecryptedLocalStorageData('clientCode');
          const request = {
            head: {
              appName: '5PTRADE',
              appSource: '21',
              appVer: '1.0',
              key: '',
              osName: 'Web',
              requestCode: '5PCLRes',
            },
            body: {},
          };
          
          await this.loginApiShareService.checkLoginResponse(request)
            .then(async (loginresponse) => {
              //this.logApiData(clientCode,'CheckLoginResponse',JSON.stringify(request),JSON.stringify(loginresponse)); // As per vishal Jain call  no need to log this api
              //this.logApiData(clientCode,'CheckLoginResponse_withHeader',this.ApiLoginterceptorService.myrequest,JSON.stringify(loginresponse));
                 _loginresponse = loginresponse
                return _loginresponse;
             });
             return _loginresponse;
        }
        catch (e) {
         console.log(e, 'checkfor_5paisa_cookie');
          return _loginresponse;
        }
    }
    async verifyASPXAUTH(): Promise<any> {
        let clientCode = this.constantService.getDecryptedLocalStorageData('clientCode');
        let aspxresponse = null; 
          try {
            const aspxauth = this.cookieService.get('.ASPXAUTH');            
            if ((aspxauth != null || aspxauth != '') && (clientCode != null || clientCode != '')) {
              let param = clientCode + this.encryptionService.decryptUsingAES_256(JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data)).authApiChecksumPrivateKey);
              let Checksum = this.generateChecksumAuthApi(param);
              let request = {
                ClientCode: clientCode,
                Token: aspxauth,
                CheckSum: Checksum
              };
             
             await this.loginApiShareService.validateFormAuthentication(request).then(async (response) => {
              
              if (response?.error || response?.name) {
                this.logApiData(clientCode, 'Failure - Verify_ASPXAUTH', JSON.stringify(request), JSON.stringify(response));
              }
              else {
                this.logApiData(clientCode,'Verify_ASPXAUTH',JSON.stringify(request),JSON.stringify(response));
              }
              aspxresponse = response;
                return aspxresponse;
             });
             
             return aspxresponse;
            }
          }
          catch(error){
            this.logApiData(clientCode, 'Failure - verifyASPXAUTH - Catch', JSON.stringify(error), JSON.stringify('Method not found'));
            return aspxresponse;
          }
    }
    async logout_user(): Promise<void> {
        try{
            let clientcode =this.constantService.getDecryptedLocalStorageData('clientCode') == undefined ? 'JaSMYKpkOb1EV9LnfYJYbg==' : this.constantService.getDecryptedLocalStorageData('clientCode');
            let request = {
                ClientCode: clientcode,
                MachineID: "BM4653-D-039377.loca",
                ServerIP: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress(),
                ClientIP: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress()
              };
              let jwtToken ='Bearer '+ this.getCookie('JwtToken') == undefined || null || '' ? this.cookieService.get('JwtToken') : this.getCookie('JwtToken');
            await this.loginApiShareService.logout_user(request,jwtToken)
            .then(async (aspxresponse) => {
                this.logApiData(clientcode,'Logout',JSON.stringify(request),JSON.stringify(aspxresponse));
            });
        }
        catch(e){
            console.log(e);
        }
    }
    getCookie(cookieName: string) : string {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
    
        for (let i = 0; i < cookieArray.length; i++) {
          let cookie = cookieArray[i];
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
          }
          if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
          }
          
        }
        return '';
    }
    async getOTPForLogin(isTotpEnabled) : Promise<any>{
        try{
          let clientCode =this.constantService.getDecryptedLocalStorageData('clientCode').trim();
          let secretkey = this.storeDataServiceService.swarajauthSecretKey == '' ? this.constantService.swarajauthSecretKey : this.storeDataServiceService.swarajauthSecretKey;
          let DDMMYYY = this.formatDateToDDMMYYYY();
          let ApiKey = this.encryptionService.hashSHA256SecretKey(secretkey+ '|' + DDMMYYY + '|' + clientCode);
          let response = null;
        
        const request = {
          LoginId: clientCode,
          APIKey : ApiKey
        };
    
        let clevertapData = {
          CT_Source: 'weblogin',
          User_Details: clientCode,
          Resend_Otp_Click: 'No',
          Screen_Name: isTotpEnabled == 'Y' ? 'TOTP' : 'Fresh',
        };
        
    
        this.storeDataServiceService.clevertapCommonFunction('Generate_OTP', clevertapData);
        await this.loginApiShareService.getOTPForLogin(request)
          .then(async (otpResponse: any) => {
            if (otpResponse?.error) {
              this.logApiData(clientCode, 'Failure - resendOTP', JSON.stringify(request), JSON.stringify(otpResponse));
              return
            }
            else {
              this.logApiData(clientCode, 'resendOTP', JSON.stringify(request), JSON.stringify(otpResponse));
            }
            
            this.constantService.setEncryptedLocalStorageData('mobileNumberHidden', otpResponse.MobileNumber);
            response = otpResponse;
            return response;
          });
          return response;
        }
        catch(e){
          console.log(e,'exception occured in getOTPForLogin');
          return null;
        }
        
    
    }
    lastUserLogingDetails() {
        const userDetails = this.cookieService.get('userDetails');
        const decryptedData = this.encryptionService.decryptUsingAES_256(userDetails);
        if (decryptedData != null || decryptedData != undefined) {
          let userlog = JSON.parse(decryptedData);
          let finaluserlog = [];
          let alreadyExist = true;
          if (userlog.length > 0) {
            for (let i = 0; i < userlog.length; i++) {
              if (userlog[i].clientCode == this.constantService.getDecryptedLocalStorageData('clientCode')) {
                this.storeDataServiceService.userDetails[0]['clientCode'] = userlog[i].clientCode;
                this.storeDataServiceService.userDetails[0]['userName'] = userlog[i].userName == null ? this.constantService.getDecryptedLocalStorageData('ClientName') : userlog[i].userName;
                this.storeDataServiceService.userDetails[0]['userEmailId'] = userlog[i].userEmailId == null ? this.constantService.getDecryptedLocalStorageData('Email') : userlog[i].userEmailId;
                this.storeDataServiceService.userDetails[0]['userMobileNo'] = userlog[i].userMobileNo == null ? this.constantService.getDecryptedLocalStorageData('mobileNumber') : userlog[i].userMobileNo;
                this.storeDataServiceService.userDetails[0]['clientStatus'] = userlog[i].clientStatus == null ? this.constantService.getDecryptedLocalStorageData('ClientStatus') : userlog[i].clientStatus;
                //this.storeDataServiceService.userDetails[0]['redirectionType'] = userlog[i].redirectionType == null ? 0 : userlog[i].redirectionType;
                alreadyExist = false;
                let removeLastuserData = userlog.splice(i, 1);
                console.log('removeLastuserData', removeLastuserData);
                console.log('userlog', userlog);
                finaluserlog = this.storeDataServiceService.userDetails.concat(userlog);
                console.log('userloglatest', userlog);
              }
            }
            if (alreadyExist) {
              if(userlog.length == 4){
                const removeLastuserData = userlog.slice(0,userlog.length - 1);
                userlog = removeLastuserData ;
              }
              this.storeDataServiceService.userDetails[0]['clientCode'] = this.constantService.getDecryptedLocalStorageData('clientCode');
              this.storeDataServiceService.userDetails[0]['userName'] = this.constantService.getDecryptedLocalStorageData('ClientName') == undefined ? null : this.constantService.getDecryptedLocalStorageData('ClientName');
              this.storeDataServiceService.userDetails[0]['userEmailId'] = this.constantService.getDecryptedLocalStorageData('Email') == undefined ? null : this.constantService.getDecryptedLocalStorageData('Email');
              this.storeDataServiceService.userDetails[0]['userMobileNo'] = this.constantService.getDecryptedLocalStorageData('mobileNumber') == undefined ? null : this.constantService.getDecryptedLocalStorageData('mobileNumber');
              this.storeDataServiceService.userDetails[0]['clientStatus'] = this.constantService.getDecryptedLocalStorageData('ClientStatus') == undefined ? null : this.constantService.getDecryptedLocalStorageData('ClientStatus');
              this.storeDataServiceService.userDetails[0]['redirectionType'] = this.storeDataServiceService.userDetails[0]['redirectionType'] == null ? '0' : this.storeDataServiceService.userDetails[0]['redirectionType'];
              finaluserlog = this.storeDataServiceService.userDetails.concat(userlog);
            }

          }
          else{
              this.storeDataServiceService.userDetails[0]['clientCode'] = this.constantService.getDecryptedLocalStorageData('clientCode');
              this.storeDataServiceService.userDetails[0]['userName'] = this.constantService.getDecryptedLocalStorageData('ClientName') == undefined ? null :this.constantService.getDecryptedLocalStorageData('ClientName');
              this.storeDataServiceService.userDetails[0]['userEmailId'] = this.constantService.getDecryptedLocalStorageData('Email') == undefined ? null :this.constantService.getDecryptedLocalStorageData('Email');
              this.storeDataServiceService.userDetails[0]['userMobileNo'] = this.constantService.getDecryptedLocalStorageData('mobileNumber')== undefined ? null :this.constantService.getDecryptedLocalStorageData('mobileNumber');
              this.storeDataServiceService.userDetails[0]['clientStatus'] = this.constantService.getDecryptedLocalStorageData('ClientStatus') == undefined ? null :this.constantService.getDecryptedLocalStorageData('ClientStatus');
              this.storeDataServiceService.userDetails[0]['redirectionType'] = this.storeDataServiceService.userDetails[0]['redirectionType'] == null ? '0' : this.storeDataServiceService.userDetails[0]['redirectionType'];
              finaluserlog = this.storeDataServiceService.userDetails;
          }
          this.storeDataServiceService.userDetails = finaluserlog;
          console.log('userDetails-1', this.storeDataServiceService.userDetails);
    
        }
        else{
          this.storeDataServiceService.userDetails[0]['clientCode'] = this.constantService.getDecryptedLocalStorageData('clientCode');
          this.storeDataServiceService.userDetails[0]['userName'] = this.constantService.getDecryptedLocalStorageData('ClientName') == undefined ? null :this.constantService.getDecryptedLocalStorageData('ClientName');
          this.storeDataServiceService.userDetails[0]['userEmailId'] = this.constantService.getDecryptedLocalStorageData('Email') == undefined ? null :this.constantService.getDecryptedLocalStorageData('Email');
          this.storeDataServiceService.userDetails[0]['userMobileNo'] = this.constantService.getDecryptedLocalStorageData('mobileNumber')== undefined ? null :this.constantService.getDecryptedLocalStorageData('mobileNumber');
          this.storeDataServiceService.userDetails[0]['clientStatus'] = this.constantService.getDecryptedLocalStorageData('ClientStatus') == undefined ? null :this.constantService.getDecryptedLocalStorageData('ClientStatus');
          this.storeDataServiceService.userDetails[0]['redirectionType'] = this.storeDataServiceService.userDetails[0]['redirectionType'] == null ? '0' : this.storeDataServiceService.userDetails[0]['redirectionType'];
        }
    }
    async checkforTradestationRedirection(clientcode,redirectiontype) : Promise<void>{
          try
          {
            if(redirectiontype == null || redirectiontype != 1){
              let requestdata = {
                "head": {
                  "appName": "CommonAPI",
                  "osName": "Web",
                  "appVersion": "1.0",
                  "clientIP": this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP : this.storeDataServiceService.getIpAddress(),
                },
                "body": {
                  "clientCode": clientcode
                }
              };
              let Jwttoken = this.constantService.getDecryptedLocalStorageData('JwtToken');
              let authorization = 'Bearer ' + Jwttoken;
              await this.loginApiShareService.checkforTradestationRedirectionApi(requestdata,authorization).then(async(response)=>{
                if(response?.error || response?.name){
                  this.storeDataServiceService.userDetails[0]['redirectionType'] = '0'; // for old tradestation
                  this.logApiData(clientcode,'Failure-checkforTradestationRedirectionApi',JSON.stringify(requestdata),JSON.stringify(response));
                  return;
                }
                else{
                  this.logApiData(clientcode,'checkforTradestationRedirectionApi',JSON.stringify(requestdata),JSON.stringify(response));
                }
                if(response != null){
                  if(response.StatusCode == 200){
                    this.storeDataServiceService.userDetails[0]['redirectionType'] = response.IsTSNewRedirection.toString();
                  }
                  else if(response.StatusCode == 404){
                    this.storeDataServiceService.userDetails[0]['redirectionType'] = '0'; // for old tradestation
                  }
                  else if(response.StatusCode == 401){
                    this.storeDataServiceService.userDetails[0]['redirectionType'] = '0'; // for old tradestation
                  }
                }
              });
            }
          }
          catch(e){
            console.log(e,'checkfortradestationRedirection');
          }
    }
    async checkForDormantAccount(): Promise<any> {
        let Response = null;
        try{
          var jwtToken = this.constantService.getDecryptedLocalStorageData('JwtToken');
          var clientCode = this.constantService.getDecryptedLocalStorageData('clientCode')
          const date = new Date();
          const todayDt = this.datepipe.transform(date, this.constantService.checksumDateFormate);
          let ip = this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP :this.storeDataServiceService.getIpAddress();
          const parameters = clientCode + 'WEB' + 'Client' + clientCode + todayDt;
          let activationChecksumkey = this.generateCheckSumActivation(parameters);
          let objrequest = {
            head: {
              checkSumValue: activationChecksumkey,
              ipAddress: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP : this.storeDataServiceService.getIpAddress(),
              appSource: "WEB"
            },
            body: {
              clientCode: clientCode,
              userId: clientCode,
              userType: "Client",
            }
          }
          const Authorizationkey = 'Bearer ' + jwtToken;
          await this.loginApiShareService.checkDormantClient(Authorizationkey,objrequest)
            .then(async(response: any) => {
              if(response?.error || response?.name){
                this.logApiData(clientCode, 'Failure - checkForDormantAccount', JSON.stringify(objrequest), JSON.stringify(response));
                Response = response
              }
              else{
                this.logApiData(clientCode, 'checkForDormantAccount', JSON.stringify(objrequest), JSON.stringify(response));
              }
              Response = response;
              })
            .catch(async (error) => {
              this.logApiData(clientCode, 'Failure - checkForDormantAccount-catch', JSON.stringify(objrequest), JSON.stringify(error));
              return Response = error;
          });
          return Response;
        }
        catch(e){
          console.log(e,'checkForDormantAccount');
        }
    }
    formatDateToDDMMYYYY(): string {
        const formattedDate = new Date().toLocaleString("en-IN", {timeZone: "Asia/Kolkata",year: 'numeric', month: '2-digit', day: '2-digit'});
        return formattedDate.replace(/\//g, '');
      
    } 
    async generateVendorTokenAPICall() {
        try {
            const vid = this.constantService.getDecryptedLocalStorageData("vid");
             await this.GenerateVendorToken(vid).then((result: any) => {
            if (result?.StatusMessage === "Success" && result?.RedirectionType === "Get") {
                const returnUrl = `${result.Url}${result.Token}`;
                localStorage.removeItem('vid');
                window.location.href = returnUrl;
            }
            // else
            // {
            //   return result;
            // }
          });
        } catch (error) {
            console.error('generateVendorTokenAPICall:', error);
        }
    }
    async GenerateVendorToken(vid: string): Promise<any> {
        let jwtToken = this.constantService.getDecryptedLocalStorageData('JwtToken');
        let clientCode = this.constantService.getDecryptedLocalStorageData('clientCode') || '12345678';
    
        const request = {
          head: {
              appName: "CommonAPI",
              osName: "Web",
              appVersion: "1.0",
              clientIP: "::1"
          },
          body: {
              ClientCode: clientCode,
              vid: vid,
              TimeStamp: "",
              RUrl: "",
              Email: "",
              ClientName: ""
          }
      };

        const authorizationKey = `Bearer ${jwtToken}`;        

        try {
            let generateVendorTokenResponse;
             await this.loginApiShareService.GenerateVendorToken(authorizationKey, request).then((response: any) => {
                if (response?.error || response?.name) {
                    this.logApiData(clientCode, 'Failure - GenerateVendorToken', JSON.stringify(request), JSON.stringify(response));
                    return null;
                }
                generateVendorTokenResponse=response;
                this.logApiData(clientCode, 'GenerateVendorToken', JSON.stringify(request), JSON.stringify(generateVendorTokenResponse));
                return generateVendorTokenResponse;
            });
            return generateVendorTokenResponse;
        } catch (error) {
            console.error('Error in GenerateVendorToken:', error);
            return null;
        }
    }
    async ValidateWebViewTokenAPICall(vendorSSOToken : string ) {
      try {
           await this.ValidateWebViewToken(vendorSSOToken).then((result: any) => {
          if (result?.StatusMessage === "Success" && result?.StatusCode === 200) {
              const returnUrl = result.ProductUrl;
              localStorage.removeItem('vendorSSOToken');
              
              const _5paisacookie = this.cookieService.get('5paisacookie');
              console.log("__5paisacookie :",_5paisacookie);
              const _JwtToken = this.cookieService.get('JwtToken');
              console.log("_JwtToken :",_JwtToken);
              const _IsDealer = this.cookieService.get('IsDealer');
              console.log("_IsDealer :",_IsDealer);
              const _ASPXAuth = this.cookieService.get('.ASPXAuth');
              console.log("_ASPXAuth :",_ASPXAuth);

              // Read all cookies
              const allCookies = this.cookieService.getAll();
              console.log("allCookies :", allCookies);
              window.location.href = returnUrl;
          }
          else
          {
            //this.showTosterMessage("warning",result.StatusMessage);
            localStorage.removeItem('vendorSSOToken');
            this.showTosterMessage("error",result.StatusMessage);
          }
        });
      } catch (error) {
          console.error('ValidateWebViewTokenAPICall:', error);
      }
    }
    showTosterMessage(type: 'success' | 'error' | 'warning' | 'info', message: string): void {
    const options = {
      timeOut: 3000,
      closeButton: true,
      progressBar: true
    };
  
    const titleMap = {
      success: 'Success',
      error: 'Error',
      warning: 'Warning',
      info: 'Information'
    };
  
    const toastrFunctionMap = {
      success: this.toastr.success,
      error: this.toastr.error,
      warning: this.toastr.warning,
      info: this.toastr.info
    };
  
    toastrFunctionMap[type].call(this.toastr, message, titleMap[type], options);
    }
    async ValidateWebViewToken(vendorSSOToken: string): Promise<any> {  
    const request = {
      head: {
          appName: "CommonAPI",
          osName: "Web",
          appVersion: "1.0",
          clientIP: "::1"
      },
      body: {
        token: vendorSSOToken
      }
  };

    const authorizationKey = 'Basic NVBNb2JpbGVXZWJWaWV3OjVQTW9iaWxlV2ViVmlld0AyMDI0';  
    const ReqSource = 'MobileSso'      

    try {
        let ValidateWebViewTokenResponse;
         await this.loginApiShareService.ValidateWebViewToken(authorizationKey, ReqSource, request).then((response: any) => {
            if (response?.error || response?.name) {
                this.logApiData('12345678', 'Failure - ValidateWebViewToken', JSON.stringify(request), JSON.stringify(response));
                return null;
            }
            ValidateWebViewTokenResponse=response;
            this.logApiData('12345678', 'ValidateWebViewToken', JSON.stringify(request), JSON.stringify(ValidateWebViewTokenResponse));
            return ValidateWebViewTokenResponse;
        });
        return ValidateWebViewTokenResponse;
    } catch (error) {
        console.error('Error in ValidateWebViewToken:', error);
        return null;
    }
    }
    async decodeSettingsData(): Promise<any> {
      let decryptedData = JSON.parse(this.encryptionService.decryptUsingAES_256(this.settings.data));
      Object.entries(decryptedData).forEach(([key, encryptedValue]) => {
        try {
          decryptedData[key] = this.encryptionService.decryptUsingAES_256(encryptedValue);  // Decrypt each value
        } catch (error) {
          console.error(`Error decrypting key: ${key}`, error);
          decryptedData[key] = "Error decrypting"; // Handle error case
        }
      });
      return decryptedData;
    }
    async CheckForFreezeAccount(): Promise<any> {
      try{
        let Response = null;
        var jwtToken = this.constantService.getDecryptedLocalStorageData('JwtToken');
        var clientCode = this.constantService.getDecryptedLocalStorageData('clientCode')
        let objrequest = {
          head: {
            appName: "CommonAPI",
            osName: "WebLogin",
            appVersion: "1.0",
            clientIP: this.storeDataServiceService.getIpAddress() == undefined ? this.constantService.LocalIP : this.storeDataServiceService.getIpAddress(),
          },
          body: {
            ClientCode: clientCode
          }
        }

        const Authorizationkey = 'Bearer ' + jwtToken;
        await this.loginApiShareService.getFreezeStatus(Authorizationkey,objrequest)
          .then(async(response: any) => {
            if(response?.error || response?.name){
              this.logApiData(clientCode, 'Failure - getFreezeStatus', JSON.stringify(objrequest), JSON.stringify(response));
              return Response = response;
            }
            else{
              this.logApiData(clientCode, 'getFreezeStatus', JSON.stringify(objrequest), JSON.stringify(response));
            }
            return Response = response;
            })
          .catch(async (error) => {
            this.logApiData(clientCode, 'Failure - getFreezeStatus-catch', JSON.stringify(objrequest), JSON.stringify(error));
            return Response = error;
        });
        this.apiratelimit ++
        return Response;
      }
      catch(e){
        console.log(e,'CheckForFreezeAccount');
      }
    }
  
}

