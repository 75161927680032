import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantService } from 'src/app/service/constant.service';
import { StoreDataServiceService } from 'src/app/store-data-service.service';

@Component({
  selector: 'app-option-selection',
  templateUrl: './option-selection.component.html',
  styleUrls: ['./option-selection.component.css']
})
export class OptionSelectionComponent {

  returnUrl : string
  istradstation : boolean = true;
  isFnO : boolean = false;
  isChart : boolean = false;

  constructor(
    private activatedRouter: ActivatedRoute,
    private storeDataServiceService : StoreDataServiceService,
    private constantService: ConstantService
  ){

  }
  ngOnInit(): void {
    this.returnUrl = this.constantService.getDecryptedLocalStorageData('returnUrl') || this.constantService.getDecryptedLocalStorageData('vid');
      if(this.returnUrl == undefined || this.returnUrl == null){
          let existingUserSelectedUrl = this.constantService.getDecryptedLocalStorageData('selectedOption'); // this for user who already selected the option
          //let selectedOption = this.storeDataServiceService.getSelectedUrl();
          if(existingUserSelectedUrl != undefined){
            this.setDefaultUrl(existingUserSelectedUrl);
          }
          // else if(selectedOption != undefined){
          //   this.setDefaultUrl(selectedOption);
          // }
          else{
            this.setDefaultUrl('Tradestation');
          }
      }
      else{
        localStorage.removeItem('selectedOption');
      }
  }
  handleChange(event: any){
    if(event.target.value == 'Tradestation'){
      this.istradstation = true;
      this.isFnO = false;
      this.isChart = false;
    }
    else if(event.target.value == 'FnO'){
      this.isFnO = true;
      this.istradstation = false;
      this.isChart = false;
    }
    else{
      this.isChart = true;
      this.isFnO = false;
      this.istradstation = false;
    }
    this.setSelectedUrl(event.target.value);
  }
  setDefaultUrl(selectedOption: string){
    if(selectedOption == 'Tradestation'){
      this.istradstation = true;
      this.isFnO = false;
      this.isChart = false;
      this.constantService.setEncryptedLocalStorageData('selectedOption',selectedOption);
    }
    else if(selectedOption == 'FnO'){
      this.isFnO = true;
      this.istradstation = false;
      this.isChart = false;
      this.constantService.setEncryptedLocalStorageData('selectedOption',selectedOption);
    }
    else{
      this.isChart = true;
      this.isFnO = false;
      this.istradstation = false;
      this.constantService.setEncryptedLocalStorageData('selectedOption',selectedOption);
    }
  }
  setSelectedUrl(selectedurl: string){
    try{
      this.storeDataServiceService.setSelectedUrl(selectedurl);
      this.constantService.setEncryptedLocalStorageData('selectedOption',selectedurl);
    }
    catch(e){
      console.log(e);
    }
  }
}
