<div class="main-wrapper freeze"  >
    <div class="header-wrapper d-flex justify-content-between align-items-center">
        <h3 class="m-0">Account Freezed</h3>
        <span class="icon-cross"></span>
    </div>
    <div class="content-wrapper">
        <div class="freeze-alert">
            <img src="https://images.5paisa.com/SPOT/login/icon-freeze.png"/>
            <span>You have been marked as Freezed</span>
        </div>
        <div class="freeze-message">
            <span>For enabling trading in your account again, Kindly validate your OTP.
                This may take upto 5 mins once the OTP is validated.</span>
        </div>
        <div class="bottom-button-wrapper d-flex">
            <button
            type="button"
            class="btn-border btn-block top_space"
            (click)="skipForNow()"
          >
          Skip for now
          </button>
            <button
              type="button"
              class="btn-dark btn-block top_space"
              (click)="openOtpDialog()"
            >
            Unfreeze Account
            </button>
          </div>
    </div>
</div>

<div class="main-wrapper unfreeze" style="display: none;">
    <div class="header-wrapper d-flex justify-content-between align-items-center">
        <h3 class="m-0"><span><img src="https://images.5paisa.com/SPOT/login/icon-success.png"/></span> Unfreeze Request Taken</h3>
        <span class="icon-crossWhite"></span>
    </div>
    <div class="content-wrapper">
        <div class="freeze-alert">
            <img src="https://images.5paisa.com/SPOT/login/icon-unfreeze.png"/>
            <span>Your unfreeze request has been successfully taken.</span>
        </div>
        <div class="freeze-message">
            <span>This may take up to 5 mins. You can resume trading once it is unfreezed. Status will be updated to you via email and sms.</span>
        </div>
        <div class="bottom-button-wrapper d-flex">
            <button
              type="button"
              class="btn-dark btn-block top_space"
            >
            Okay
            </button>
          </div>
    </div>
</div>