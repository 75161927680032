<app-loader *ngIf="showLoader"></app-loader>
<div class="otp-verifications">
    <div class="inner-wrapper">
        <form class="form-field" [formGroup]="otpForm" novalidate>
            <div class="header-wrraper">
                <h3>OTP Verification</h3>
            </div>

            <p>We have sent an OTP on your mobile number and registered email address.</p>
            <div class="otp-box-wrap">
                <label> ENTER OTP </label>
                <span class="resend-otp" *ngIf="this.play"><span class="otp-time">{{ mins }}:{{ secs }}</span></span>
                <span class="resend-otp" *ngIf="!this.play" (click)="resendOTP()">RESEND OTP</span>
            </div>
            <div class="otp-verifications-input">
                <div class="otp-box">
                        <input type="tel" maxlength="1" #otpOne
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                        autocomplete="off" autofocus
                        matInput
                        [(ngModel)]="otpArr[0]"
                        formControlName="otpDigitOne"
                        (keydown)="mpinInput($event, 0)" />
                        <input type="tel" maxlength="1" #otpTwo
                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                         autocomplete="off" autofocus
                         matInput
                         [(ngModel)]="otpArr[1]"
                         formControlName="otpDigitTwo"
                         (keydown)="mpinInput($event, 1)" />
                        <input type="tel" maxlength="1" #otpThree
                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                         autocomplete="off" autofocus
                         matInput
                         [(ngModel)]="otpArr[2]"
                         formControlName="otpDigitThree" 
                         (keydown)="mpinInput($event, 2)" />
                         
                         <input type="tel" maxlength="1" #otpFour
                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                         autocomplete="off" autofocus
                         matInput
                         [(ngModel)]="otpArr[3]"
                         formControlName="otpDigitFour"
                         (keydown)="mpinInput($event, 3)" />
                        <input type="tel" maxlength="1" #otpFive
                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                         autocomplete="off" autofocus
                         matInput
                         [(ngModel)]="otpArr[4]" 
                         formControlName="otpDigitFive"
                         (keydown)="mpinInput($event, 4)"/>
                        <input type="tel" maxlength="1" #otpSix
                         oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\.*)\./g, '$1');"
                         formControlName="otpDigitOne" autocomplete="off" autofocus
                         matInput
                         [(ngModel)]="otpArr[5]" 
                         formControlName="otpDigitSix"
                         (keydown)="mpinInput($event, 5)"/>
                </div>
            </div>
            <div class="error-box" *ngIf="errormsg">
                <img src="https://images.5paisa.com/login/Error.png">
                <span>{{errormsg}}</span>
            </div>
            <button class="btn-dark btn-block" (click)="verifyoptForNominee()" [disabled]="disableBtn" id="btnVerifyOTPMPIN">Verify</button>
        </form>
    </div>
</div>